import React, { useState } from 'react';

interface RegistrationPopupProps {
  userId: string;
  password: string;
  url: string;
  onClose: () => void;
}

const RegistrationPopup: React.FC<RegistrationPopupProps> = ({ userId, password,url, onClose }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopy = (): void => {
    let newText = `userId: ${userId} \n password: ${password} \n url: ${url}`
    navigator.clipboard.writeText(newText);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[#00000091] flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <div className="text-center mb-4">
          <h2 className="text-xl font-semibold">Registration Successful</h2>
        </div>
        <div className="mb-4">
          <div className="flex justify-between mb-2">
            <label className="font-medium">User ID:</label>
            <span>{userId}</span>
          </div>
          <div className="flex mb-2 justify-between">
            <label className="font-medium">Password:</label>
            <span>{password}</span>
          </div>
          <div className="flex justify-between">
            <label className="font-medium">URL:</label>
            <span>{url}</span>
          </div>
        </div>
        <div className="grid  gap-2 ">          
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded"
            onClick={() => handleCopy()}
          >
            Copy Credentials
          </button>
          
          <button
            className="bg-gray-500 text-white py-2 px-4 rounded"
            onClick={onClose}
          >
            Close
          </button>        
        </div>
        {copied && <div className="text-green-500 mt-2 text-center">Copied!</div>}
        
      </div>
    </div>
  );
};

export default RegistrationPopup;
