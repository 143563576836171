import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch, Modal } from "antd";
import axios from "axios";
import { showFailedToastAlert } from "../utils/toastAlerts";
import { getToken } from "../utils/tokentUtils";
import { BsBarChartFill } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import FormSkeleton from "../components/FormSkeleton";
import HandlingPagination from "./HandlingPagination";
import { apiBaseUrl } from "../utils/urls";

const Customer = () => {
  const [customers, setCustomers] = useState([]); // Initialize as an empty array
  const [searchInput, setSearchInput] = useState("");
  const [dataForTable, setTable] = useState([]); // Change from null to an empty array

  const fetchCustomers = async () => {
    try {
      const token = getToken();
      const response = await axios.post(
        `${apiBaseUrl}/admin/customers/search`,
        {
          length: 10,
          start: 0,
          orderBy: "uid",
          orderDir: "asc",
          search: searchInput,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === "success") {
        setCustomers(response.data.data.data || []); // Ensure it's an array
      } else {
        showFailedToastAlert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      showFailedToastAlert(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [searchInput]);

  const handleDelete = async (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this customer?",
      onOk: async () => {
        try {
          const token = getToken();
          await axios.delete(`${apiBaseUrl}/admin/customers/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          fetchCustomers();
        } catch (error) {
          showFailedToastAlert("Failed to delete the customer.");
        }
      },
    });
  };

  const handleAssignPlan = async (assignPlanUrl) => {
    Modal.confirm({
      title: "Are you sure you want to assign a plan to this customer?",
      onOk: async () => {
        try {
          const token = getToken();
          await axios.get(assignPlanUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          fetchCustomers();
        } catch (error) {
          showFailedToastAlert("Failed to assign plan to the customer.");
        }
      },
    });
  };

  const columns = [
    {
      name: "UID",
      selector: (row) => row.uid,
    },
    {
      name: "Name",
      selector: (row) => (
        <div className="flex gap-[10px] cursor-pointer">
          <div className="h-[25px] w-[25px] bg-[#D9D9D9] rounded-full"></div>
          <div>
            <p className="text-[14px] text-[#5F6368]">{row.name}</p>
            <p className="text-[11px] text-[#5F6368]">{row.email}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Created At",
      selector: (row) => row.created_at,
    },
    {
      name: "Subscription",
      selector: (row) => row.subscription,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-2 justify-start align-middle ">
          <p onClick={() => handleAssignPlan(row.assign_plan)}>
            <BsBarChartFill className="text-[#6690eb] cursor-pointer" size={20} />
          </p>
          <p onClick={() => handleDelete(row.uid)}>
            <MdOutlineCancel className="text-[#EA5455] cursor-pointer" size={20} />
          </p>
        </div>
      ),
    },
  ];

  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  const data = Array.isArray(dataForTable) ? dataForTable.map((customer) => ({
    uid: customer.uid,
    name: customer.name,
    email: customer.email,
    status: customer.status,
    created_at: customer.created_at,
    subscription: customer.subscription,
  })) : [];

  return (
    <div className="bg-white">
      <div className="mt-[16px] pt-[23px] pb-[12px] flex items-center justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <h2 className="text-[#5F6368] text-[16px] font-bold pl-[19.74px]">Customers</h2>
        <input
          type="text"
          placeholder="Search customers..."
          className="border p-[10px] text-[#5F6368] rounded-[3px]"
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>

      {customers.length === 0 ? (
        <FormSkeleton
          titles={["UID", "Name", "Created At", "Subscription", "Action"]}
        />
      ) : (
        <DataTable columns={columns} data={data} selectableRows className="px-[10px] h-[550px]" />
      )}

      <HandlingPagination
        data={customers} // Pass the customers array directly
        getDataForTable={getDataForTable}
      />
    </div>
  );
};

export default Customer;
