import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Addnewplan from "../components/Addnewplan";
import Customer from "../components/Customer";
import Newsubscription from "../components/Newsubscription";
import Plans from "../components/Plans";
import Subscriptions from "../components/Subscriptions";
import Dashboard from "../pages/Dashboard";
import Dashboardview from "../pages/Dashboardview";
import Login from "../pages/Login";
import "react-toastify/dist/ReactToastify.css";
import Newcustomer from "../components/Newcustomer";
import ChooseSendingServer from "../components/ChooseSendingServer";
import EditCustomer from "../components/EditCustomer";
import SubscriptionLogs from "../components/SubscriptionLogs";
import Currencies from "../components/Currencies";
import AddNewCurrencies from "../components/AddNewCurrencies";
import UpdateCurrencies from "../components/UpdateCurrencies";
import SenderId from "../components/SenderId";
import PhoneNumbers from "../components/PhoneNumbers";
import Tags from "../components/Tags";
import Keywords from "../components/Keywords";
import { ThemeCustomizer } from "../components/ThemeCustomizer";
import Blacklists from "../components/Blacklists";
import SpamWord from "../components/SpamWord";
import Administrators from "../components/Administrators";
import Roles from "../components/Roles";
import Settings from "../components/Settings";
import Countries from "../components/Countries";
import Languages from "../components/Languages";
import PaymentGateways from "../components/PaymentGateways";
import EmailTemplates from "../components/EmailTemplates";
import UpdateApplication from "../components/UpdateApplication";
import Invoices from "../components/Invoices";
import Reports from "../components/Reports";
import EditSendingServer from "../components/EditSendingServer";
import EditPlan from "../components/EditPlan";
import EditSendingServerNSES from "../components/EditSendingServerNSES";
import EditPaymentGateways from "../components/EditPaymentGateways";
import UpdateEmailTemplate from "../components/UpdateEmailTemplate";
import CreateCountries from "../components/CreateCountries";
import CreateLanguage from "../components/CreateLanguage";
import EditRole from "../components/EditRole";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
// we can uninstall this for production.
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AddCoverage from "../components/AddCoverage";
import Account from "../components/Account";
import CreateAdministrator from "../components/CreateAdministrator";
import CreateRole from "../components/CreateRole";
import EditSenderId from "../components/EditSenderId";
import AddNewSenderId from "../components/AddNewSenderId";
import AddNewNumber from "../components/AddNewNumber";
import CreateNewKeyword from "../components/CreateNewKeyword";
import NewTemplateTag from "../components/NewTemplateTag";
import React, { memo } from "react";
import SendingServers from "../components/SendingServers";
import SenderIdCreatePlan from "../components/SenderIdCreatePlan";
import SenderIdPlan from "../components/SenderIdPlan";
import CreateOwnServer from "../components/CreateOwnServer";
import UpdateServer from "../components/UpdateServer";
import AddNewBlacklist from "../components/AddNewBlacklist";
import Transactions from "../components/Transactions";
import EomRecords from "../components/EomRecords";
import EomRecordData from "../components/EomRecords/show";
import ProtectedRoutes from "./ProtectedRoutes";

const Router = memo(() => {
  const client = new QueryClient();

  const token = localStorage.getItem("token");

  return (
    <QueryClientProvider client={client}>
      <div>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={!token ? <Login /> : <Dashboard />} />
    
            <Route path="/admin" element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>}>
            <Route index element={<ProtectedRoutes><Dashboardview /></ProtectedRoutes>} />

            {/* accounts section */}
            <Route path="/admin/account" element={<ProtectedRoutes><Account /></ProtectedRoutes>} />
            <Route path="/admin/account?tab=notification" element={<ProtectedRoutes><Account /></ProtectedRoutes>} />

            {/* customer section */}
            <Route path="/admin/customers" element={<ProtectedRoutes><Customer /></ProtectedRoutes>} />
            <Route path="/admin/customers/:customer/show" element={<ProtectedRoutes><EditCustomer /></ProtectedRoutes>} />
            <Route path="/admin/customers/add" element={<ProtectedRoutes><Newcustomer /></ProtectedRoutes>} />
            <Route path="/admin/subscriptions" element={<ProtectedRoutes><Subscriptions /></ProtectedRoutes>} />
            <Route path="/admin/subscriptions/create" element={<ProtectedRoutes><Newsubscription /></ProtectedRoutes>} />
            <Route path="/admin/subscriptions/:subscription/logs" element={<ProtectedRoutes><SubscriptionLogs /></ProtectedRoutes>} />

            {/* plans section */}
            <Route path="/admin/plans" element={<ProtectedRoutes><Plans /></ProtectedRoutes>} />
            <Route path="/admin/plans/new_plan" element={<ProtectedRoutes><Addnewplan /></ProtectedRoutes>} />
            <Route path="/admin/plans/:plan/show" element={<ProtectedRoutes><EditPlan /></ProtectedRoutes>} />
            <Route path="/admin/plans/:plan/coverage" element={<ProtectedRoutes><AddCoverage /></ProtectedRoutes>} />
            <Route path="/admin/currencies" element={<ProtectedRoutes><Currencies /></ProtectedRoutes>} />
            <Route path="/admin/currencies/create" element={<ProtectedRoutes><AddNewCurrencies /></ProtectedRoutes>} />
            <Route path="/admin/currencies/:currencies/show" element={<ProtectedRoutes><UpdateCurrencies /></ProtectedRoutes>} />

            {/* sending section */}
            <Route path="/admin/sending" element={<ProtectedRoutes><SendingServers /></ProtectedRoutes>} />
            <Route path="/admin/sending-servers/select" element={<ProtectedRoutes><ChooseSendingServer /></ProtectedRoutes>} />
            <Route path="/admin/sending-servers/create/custom" element={<ProtectedRoutes><CreateOwnServer /></ProtectedRoutes>} />
            <Route path="/admin/sending-servers/create/:server" element={<ProtectedRoutes><UpdateServer /></ProtectedRoutes>} />
            <Route path="/admin/sending-servers/:server/editNSES" element={<ProtectedRoutes><EditSendingServerNSES /></ProtectedRoutes>} />
            <Route path="/admin/sending-servers/:server/edit" element={<ProtectedRoutes><EditSendingServer /></ProtectedRoutes>} />

            <Route path="/admin/senderid" element={<ProtectedRoutes><SenderId /></ProtectedRoutes>} />
            <Route path="/admin/senderid/create-plan" element={<ProtectedRoutes><SenderIdCreatePlan /></ProtectedRoutes>} />
            <Route path="/admin/senderid/plan" element={<ProtectedRoutes><SenderIdPlan /></ProtectedRoutes>} />
            <Route path="/admin/senderid/create" element={<ProtectedRoutes><AddNewSenderId /></ProtectedRoutes>} />
            <Route path="/admin/senderid/:senderid/show" element={<ProtectedRoutes><EditSenderId /></ProtectedRoutes>} />
            <Route path="/admin/phone-numbers" element={<ProtectedRoutes><PhoneNumbers /></ProtectedRoutes>} />
            <Route path="/admin/phone-numbers/create" element={<ProtectedRoutes><AddNewNumber /></ProtectedRoutes>} />

            {/* other sections */}
            <Route path="/admin/keywords" element={<ProtectedRoutes><Keywords /></ProtectedRoutes>} />
            <Route path="/admin/keywords/create" element={<ProtectedRoutes><CreateNewKeyword /></ProtectedRoutes>} />
            <Route path="/admin/tags" element={<ProtectedRoutes><Tags /></ProtectedRoutes>} />
            <Route path="/admin/tags/create" element={<ProtectedRoutes><NewTemplateTag /></ProtectedRoutes>} />

            {/* security section */}
            <Route path="/admin/blacklists" element={<ProtectedRoutes><Blacklists /></ProtectedRoutes>} />
            <Route path="/admin/blacklists/create" element={<ProtectedRoutes><AddNewBlacklist /></ProtectedRoutes>} />
            <Route path="/admin/spam-word" element={<ProtectedRoutes><SpamWord /></ProtectedRoutes>} />

            {/* administrative section */}
            <Route path="/admin/administrators" element={<ProtectedRoutes><Administrators /></ProtectedRoutes>} />
            <Route path="/admin/administrators/create" element={<ProtectedRoutes><CreateAdministrator /></ProtectedRoutes>} />
            <Route path="/admin/roles" element={<ProtectedRoutes><Roles /></ProtectedRoutes>} />
            <Route path="/admin/roles/create" element={<ProtectedRoutes><CreateRole /></ProtectedRoutes>} />
            <Route path="/admin/roles/:role/show" element={<ProtectedRoutes><EditRole /></ProtectedRoutes>} />

            {/* settings */}
            <Route path="/admin/settings" element={<ProtectedRoutes><Settings /></ProtectedRoutes>} />
            <Route path="/admin/countries" element={<ProtectedRoutes><Countries /></ProtectedRoutes>} />
            <Route path="/admin/countries/create" element={<ProtectedRoutes><CreateCountries /></ProtectedRoutes>} />
            <Route path="/admin/languages" element={<ProtectedRoutes><Languages /></ProtectedRoutes>} />
            <Route path="/admin/languages/create" element={<ProtectedRoutes><CreateLanguage /></ProtectedRoutes>} />
            <Route path="/admin/payment-gateways" element={<ProtectedRoutes><PaymentGateways /></ProtectedRoutes>} />
            <Route path="/admin/payment-gateways/:gateway/show" element={<ProtectedRoutes><EditPaymentGateways /></ProtectedRoutes>} />

            {/* reports */}
            <Route path="/admin/reports" element={<ProtectedRoutes><Reports /></ProtectedRoutes>} />
            <Route path="/admin/invoices" element={<ProtectedRoutes><Invoices /></ProtectedRoutes>} />
            <Route path="/admin/transactions" element={<ProtectedRoutes><Transactions /></ProtectedRoutes>} />
            <Route path="/admin/eom-records" element={<ProtectedRoutes><EomRecords /></ProtectedRoutes>} />
            <Route path="/admin/eom-records/:id" element={<ProtectedRoutes><EomRecordData /></ProtectedRoutes>} />

            <Route path="/admin/customizer" element={<ProtectedRoutes><ThemeCustomizer /></ProtectedRoutes>} />
          </Route>

          </Routes>
        </BrowserRouter>
      </div>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
});

export default Router;
