import { useState, useEffect } from "react";
import { BiSave } from "react-icons/bi";
import { FiRefreshCw } from "react-icons/fi";
import usePostQuery from "../hooks/usePostQuery";
import useGetQuery from "../hooks/useGetQuery";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../utils/toastAlerts";
import { apiBaseUrl } from "../utils/urls";
import { getToken } from "../utils/tokentUtils";

interface Currency{
  uid: String;
  name: String;
  id: number | String;
  code: String
}

interface Customer{
  first_name: String;
  last_name: String;
  id: number | number;
  uid: String;
}


const AddNewSenderId = () => {
  const token = getToken();
  const [CustomersList, setCustomerList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);


    useEffect(() => {
      const fetchSenderIdCreationData = async () => {
        try {
          let config = {
            method: "get",
            url: `${apiBaseUrl}/admin/senderid/data`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
    
          let response = await axios.request(config);
          console.log("customer list", response);

          console.log(CustomersList);
          setCurrencyList(response.data.data.currencies)
          setCustomerList(response.data.data.customers); // Ensure the correct data format
        } catch (error) {
          console.error("Error fetching customer list:", error);
        }
      };
    
      fetchSenderIdCreationData(); // Call the function here
    }, [token]);
    



  //  state of senderid
  const [addSender, setAddSender] = useState<any>({
    sender_id: "",
    status: "",
    price: "",
    billing_cycle: "",
    frequency_amount: "",
    frequency_unit: "",
    user_id: "",
    currency_id: "",
  });


  // Updates the addSender state when an input field value changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddSender((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Updates the addSender state when an select element field value changes
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setAddSender((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    try {
      const data = JSON.stringify(addSender);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/senderid`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      const response = await axios.request(config);
      showSuccessfulToastAlert(response?.data?.message);
      console.log(response);
    } catch (error) {
      console.error("Submission failed:", error);
      showFailedToastAlert(error.response?.data?.message || "An error occurred.");
    }
  };
  

  return (
    <>
      <form onSubmit={handleFormSubmission}>
        <div className="w-[50%] mt-4 p-4 shadow-xl  bg-white rounded-md">
          <p className="title w-full mb-4 text-[#5e5873] text-[1.285rem] font-[500]  ">
            Add New Sender ID
          </p>
          <p className="text-[var(--accent-gray)] leading-[1.5rem] text-justify mb-4 form-label-size ">
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              Sender ID
            </span>{" "}
            is what appears on people's phones to show who the SMS is from. It
            can be your{" "}
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              Brand Name
            </span>{" "}
            or{" "}
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              Comapany Name
            </span>
            . Set{" "}
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              Price
            </span>{" "}
            value{" "}
            <span className="bg-[#eeeeee] py-[2px] px-[3px] text-[#d8428c] ">
              0
            </span>{" "}
            for free of cost sender id on SMSVENDOR
          </p>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              htmlFor="sender_id"
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
            >
              Sender ID
              <span className="text-red-600"> *</span>
            </label>
            <input
              id="sender_id"
              name="sender_id"
              type="text"
              placeholder="Required"
              required
              value={addSender?.sender_id}
              onChange={handleInputChange}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
            />
          </div>
          <div className=" flex gap-2 mb-6 flex-col">
            <label
              htmlFor="status"
              className="text-[var(--accent-gray)] form-label-size mb-[0.2875]"
            >
              Status
              <span className="text-red-600"> *</span>
            </label>

            <select
              required
              id="status"
              name="status"
              value={addSender?.status}
              onChange={handleSelectChange}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
            >
              <option className="text-gray-700" value="active">
                Active
              </option>
              <option className="text-gray-700" value="payment_required">
                Payment Required
              </option>
              <option className="text-gray-700" value="block">
                Block
              </option>
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              htmlFor="price"
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
            >
              Price
              <span className="text-red-600"> *</span>
            </label>
            <input
              required
              id="price"
              name="price"
              type="text"
              placeholder="required"
              value={addSender?.price}
              onChange={handleInputChange}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
            />
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              htmlFor="billing_cycle"
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
            >
              Billing cycle
              <span className="text-red-600"> *</span>
            </label>
            <select
              required
              id="billing_cycle"
              name="billing_cycle"
              value={addSender?.billing_cycle}
              onChange={handleSelectChange}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
            >
              <option className="text-gray-700" value="daily">
                Daily
              </option>
              <option className="text-gray-700" value="monthly">
                Monthly
              </option>
              <option className="text-gray-700" value="yearly">
                Yearly
              </option>
              <option className="text-gray-700" value="custom">
                Custom
              </option>
            </select>
          </div>
          {addSender?.billing_cycle === "custom" && (
            <div className="flex gap-2 ">
              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  htmlFor="frequency_amount"
                  className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                >
                  Frequency Amount
                  <span className="text-red-600"> *</span>
                </label>
                <input
                  required
                  id="frequency_amount"
                  name="frequency_amount"
                  type="text"
                  value={addSender?.frequency_amount}
                  onChange={handleInputChange}
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] px-[1rem] font-[400]  "
                />
              </div>
              <div className=" flex gap-2 mb-6 flex-col">
                <label
                  htmlFor="frequency_unit"
                  className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
                >
                  Frequency Unit
                  <span className="text-red-600"> *</span>
                </label>
                <select
                  id="frequency_unit"
                  name="frequency_unit"
                  value={addSender?.frequency_unit}
                  onChange={handleSelectChange}
                  className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
                >
                  <option className="text-gray-700" value="day">
                    Day
                  </option>
                  <option className="text-gray-700" value="week">
                    Week
                  </option>
                  <option className="text-gray-700" value="month">
                    Month
                  </option>
                  <option className="text-gray-700" value="year">
                    Year
                  </option>
                </select>
              </div>
            </div>
          )}

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              htmlFor="user_id"
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
            >
              Select Customer
              <span className="text-red-600"> *</span>
            </label>
            <select
              required
              id="user_id"
              name="user_id"
              value={addSender?.user_id}
              onChange={handleSelectChange}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
            >
              {CustomersList?.map((val : Customer) => {
                return (
                  <option
                    key={val.uid}
                    value={val.id}
                  >{`${val.first_name} ${val.last_name}`}</option>
                );
              })}
            </select>
          </div>

          <div className=" flex gap-2 mb-6 flex-col">
            <label
              htmlFor="currency_id"
              className="text-[var(--accent-gray)] text-[12px] form-label-size mb-[0.2875] "
            >
              Currency
              <span className="text-red-600"> *</span>
            </label>
            <select
              required
              id="currency_id"
              name="currency_id"
              value={addSender?.currency_id}
              onChange={handleSelectChange}
              className="bg-white outline-1 outline-[var(--accent-orange)] border border-[#d8d6de] rounded-[0.357rem] text-[var(--accent-gray)] block form-label-size py-[0.571rem] pr-[2] px-[1rem] font-[400]"
            >

              {CurrencyList?.map((val : Currency  ) => {
                return (
                  <option
                    key={val.uid}
                    value={val.id}
                  >{`${val.name} (${val.code})`}</option>
                );
              })}
            </select>
          </div>

          <div className="flex  gap-2 justify-end">
            <button className=" accent-btn ">
              <BiSave />
              Update
            </button>
            <button className=" flex gap-[0.5rem] items-center cursor-pointer bg-white text-[var(--accent-orange)] border border-[var(--accent-orange)]  mb-[1rem] py-[0.7rem] px-[1.5rem] rounded-[0.358rem] ">
              <FiRefreshCw />
              Reset
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddNewSenderId;
