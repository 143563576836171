import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../utils/tokentUtils';

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const token = getToken();

  React.useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  // Render children only if the token exists
  return token ? children : null;
};

export default ProtectedRoutes;
